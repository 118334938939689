@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.button {
  --btn-accent: white;
  --btn-bg: var(--color-ink-black);
  background-color: var(--btn-bg);
  color: var(--btn-color, var(--color-text--inverse));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  flex: 0 0 auto;
  width: fit-content;

  &:hover,
  &:focus {
    color: var(--btn-color, var(--color-text--inverse));
  }

  * {
    color: currentColor;
  }

  .icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -4px -20px -4px 16px;
    background-color: var(--btn-accent);
    color: var(--btn-icon-color, var(--color-ink-black));
  }

  .iconGapNone {
    margin-left: 0;
  }
}

.small {
  padding: 8px 16px;
  height: 32px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 16px;
  min-width: 64px;

  .icon {
    width: 24px;
    height: 24px;
  }
}

.medium {
  padding: 8px 24px;
  height: 40px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 20px;
  min-width: 80px;

  .icon {
    width: 32px;
    height: 32px;
  }
}

.large {
  padding: 12px 24px;
  height: 48px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 24px;
  min-width: 96px;

  .icon {
    width: 40px;
    height: 40px;
  }
}

.buttonGroup {
  flex-direction: column;
  display: flex;
  gap: 16px;
  align-items: flex-start;

  @include breakpoints.breakpoint(l) {
    flex-direction: row;
  }
}

.border {
  --btn-color: #191919;
  --btn-accent: #191919;
  --btn-bg: white;
  --btn-icon-color: white;
  border: 1px solid rgba(#191919, 0.3);
}

.white {
  --btn-color: #191919;
  --btn-accent: #191919;
  --btn-bg: white;
  --btn-icon-color: white;
}

.whiteLink, .blackLink {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  font-size: 16px;
  .icon {
    width: 16px;
    background-color: transparent;
  }
}

.none {
  padding: 8px 0;
  --btn-bg: transparent;
  --btn-color: var(--color-text);
}

.whiteLink {
  --btn-icon-color: white;
  --btn-color: white;
}

.blackLink {
  --btn-icon-color: #191919;
  --btn-color: #191919;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.rectangle {
  border-radius: 4px;
}

.lightIcon {
  --btn-accent: white;
  --btn-icon-color: #191919;
}

.noBackgroundIcon {
  --btn-accent: transparent;
  --btn-icon-color: currentColor;
}

.fullWidthOnMobile {
  @include breakpoints.breakpoint(s only) {
    width: 100%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #F60C9E;
}

input:focus + .slider {
  box-shadow: 0 0 1px #F60C9E;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switcherWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.foundationsButtonWrapper {
  display: flex;
  justify-content: center;
}

.accentPink {
  background: #DA2381;

  .icon {
    background: transparent;
    color: white;
  }
}

.pr24 {
  padding-right: 24px;
}
