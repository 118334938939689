@use "node_modules/@ef-global/web-ui/scss/settings/mixins/breakpoints";

.proCyclingNavInline {
  //overflow: hidden;
  grid-row-start: header-start;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: 3/main-end;

  @include breakpoints.breakpoint(l) {
    grid-column: 5/main-end;
  }

  @include breakpoints.breakpoint(xl) {
    grid-column: 6/main-end;
  }

  .proCyclingNav__menu {
    list-style: none;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    display: flex;
    font-size: 14px;
    margin: 0;
    padding-left: 0;
  }

  .ctaButton {
    --btn-bg: var(--color-pink);
    margin-left: 32px;
  }
}

.proCyclingNavOverlay {
  grid-column: bounds-start / bounds-end;
  position: relative;

  .proCyclingNav__menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin: 0;
    padding: 0 0 48px;
  }

  &:not(.active) {
    pointer-events: none;
  }
}

.efNav__item {
  line-height: 1;
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;

  .efNav__link {
    cursor: pointer;
    color: black;
  }

  &.active {
    .efNav__link {
      font-weight: bold;
    }
  }
}

.proCyclingNav__panelWrap {
  position: absolute;
  width: 100%;
  z-index: 999;
  padding-top: 24px;
}

.proCyclingNav__menu {
  grid-column: main-start / main-end;
}

.proCyclingNav__panel {
  background-color: var(--site-bg);
  grid-template-rows: min-content;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 128px;
    overflow: hidden;
    top: 100%;
    left: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 53.91%, rgba(0, 0, 0, 0.3) 153.91%);
  }

  &:not(.active) {
    pointer-events: none;
    z-index: -1;
  }

  .efNav__item {
    padding: 16px 0;
    border-bottom: 1px solid var(--color-divider);
  }
}

.proCyclingNav__toggle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  margin: auto 0 auto auto;
  cursor: pointer;
  flex: 0 0 40px;
}

.proCyclingNav__buttons {
  grid-row: 1;
  grid-column: col-10 / main-end;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * + * {
    margin-left: 16px;
  }

  .ctaButton {
    --btn-bg: var(--color-pink);
  }
}

.signButton {
  --btn-bg: white;
  --btn-color: var(--color-text);
  --btn-border: var(--color-text);
}

.ctaButton + .signButton {
  margin-left: 8px;
}

.measureComponent {
    grid-area: header-start / col-4 / header-end / main-end;
}

.navGravatar {
  display: block;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
}

.proCyclingNavInline .navDropdown {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.mobileUserNav {
  grid-column: main;
  background-color: rgba(#191919, 0.05);
  border-radius: 7px;
  padding: 16px;
  gap: 16px;
  display: flex;
  align-items: flex-start;
  margin: 16px 0;
}

.mobileUserNavList {
  margin-top: 4px;
  display: flex;
  flex-direction: column;

  > * {
    padding: 4px 0;
  }
}

.panelLink {
  font-size: 14px;
  color: black;
}

.logOut {
  margin-left: auto;
}

.dropdownButton {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 16px;

  &:before {
    content: "";
    position: absolute;
    left: -12px;
    top: -12px;
    bottom: -16px;
    right: -12px;
    width: calc(100% + 24px);
    height: calc(100% + 32px);
  }

  > svg {
    flex: 0 0 12px;
    fill: black;
  }
  > span {
    flex: 0 0 32px;
  }
}


.dropdownPanel {
  opacity: 0;
  position: absolute;
  width: 200px;
  font-size: 14px;
  top: 40px;
  background: white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);

  .panelLink {
    min-height: 32px;
    line-height: 16px;
    padding: 8px;
    transition: background-color 0.2s ease;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: rgba(#191919, 0.05);
      border-radius: 5px;
    }
  }

  &.left {
    left: 0;
  }

  &.center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.right {
    right: 0;
  }
}


svg.dropdownChevron {
  width: 12px;
  height: 12px;
}

.mobileSignIn {
  width: 100%;
  font-size: 14px;
}

.mobileSignInTitle {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.mobileSignInLink {
  display: block;
  padding: 8px;
  cursor: pointer;
  line-height: 16px;
  &:first-child {
    padding-top: 16px;
  }
}

.navDropdown {
  .navDropdownList {
    padding: 0;
    list-style: none;
  }

  .navDropdownButton {
    font-size: 14px;
    padding: 0;
    min-width: 0;
    margin: 0;

    &.active {
      font-weight: bold;
    }
  }
}

.navCollapsibleTrigger {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  cursor: pointer;

  &.active {
    font-weight: bold;
  }
}

.navCollapsibleContent {
  .navDropdownList {
    padding: 0 0 0 12px;
    list-style: none;

    .efNav__item {
      border: none;
      padding: 8px 0;
      line-height: 16px;

      &:last-child {
        padding-bottom: 24px;
      }
    }
  }
}

.efNav__item.navItemDropdown {
  padding-bottom: 0;
}

.noGravatar {
  width: 32px;
  height: 32px;
  background: #EC87BF;
  border-radius: 50%;
}
